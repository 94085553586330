<!-- eslint-disable vue/no-template-shadow -->
<template>
  <v-row class="match-height">
    <v-col cols="12">
      <app-card-code :title="`${$t('menu.nomenclador')} / ${$t('menu.flights')} / ${$t('menu.aerolinea')}`">
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                @click="$router.push({ name: 'aerolinea-flights-list' })"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                v-if="!$store.state.app.onlyShow"
                color="primary"
                class="mb-4 me-3"
                :loading="loading"
                @click="save()"
              >
                <span>{{ btnTitle }}</span>
                <v-icon>{{ icons.mdiUpdate }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-form
            v-if="!isLoading"
            class="multi-col-validation"
          >
            <v-row class="pb-5">
              <v-col
                cols="12"
                md="4"
                class="mt-5"
              >
                <v-text-field
                  v-model="item.name"
                  :label="$t('lbl.name')"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="mt-5"
              >
                <v-text-field
                  v-model="item.slug"
                  :label="$t('lbl.iata')"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="mt-5"
              >
                <v-file-input
                  v-model="isotipo"
                  accept="image/*"
                  outlined
                  dense
                  label="Isotipo"
                ></v-file-input>
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="mt-5"
              >
                <v-file-input
                  v-model="imagotipo"
                  accept="image/*"
                  outlined
                  dense
                  label="Imagotipo"
                ></v-file-input>
              </v-col>

              <v-col
                cols="12"
                md="2"
                class="mt-5"
              >
                <v-switch
                  v-model="item.is_company"
                  :label="$t('lbl.isCompany')"
                  hide-details
                  class="mt-2"
                ></v-switch>
              </v-col>
            </v-row>

            <v-row v-if="item.is_company">
              <v-col
                cols="12"
                md="12"
              >
                <strong>{{ $t('menu.products') }}</strong>
                <v-row>
                  <v-col
                    v-for="(product, i) in products"
                    :key="i"
                    cols="12"
                    md="3"
                  >
                    <v-checkbox
                      v-model="nom_tipo_products_id"
                      :label="product.name"
                      :value="product.id"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                cols="12"
                md="12"
              >
                <v-row class="mt-4">
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        md="2"
                        class="mt-2"
                      >
                        <strong>{{ $t('lbl.contacts') }}</strong>
                      </v-col>
                      <v-col
                        cols="12"
                        md="1"
                      >
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="mt-1 mx-2"
                              fab
                              dark
                              x-small
                              color="primary"
                              v-bind="attrs"
                              v-on="on"
                              @click="addContactsAfiliados"
                            >
                              <v-icon small>
                                {{ icons.mdiPlus }}
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('btn.insert') }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>

                    <v-divider class="mb-2 mt-2"></v-divider>
                    <v-row
                      v-for="(contact, indC) in contactsAfiliados"
                      :key="indC"
                    >
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <Contact
                          :pos="indC"
                          :contact="contact"
                          :product-item="nom_tipo_products_id"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <v-row
                          v-for="(email, indE) in contact.emails"
                          :key="indE"
                        >
                          <Email
                            :pos-contact="indC"
                            :pos="indE"
                            :email="email"
                          />
                        </v-row>
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <v-row
                          v-for="(phone, indT) in contact.telefonos"
                          :key="indT"
                        >
                          <Telefono
                            :pos-contact="indC"
                            :pos="indT"
                            :telefono="phone"
                          />
                        </v-row>
                      </v-col>
                      <v-col
                        cols="12"
                        md="12"
                      >
                        <v-divider></v-divider>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </app-card-code>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiMapPlus,
  mdiMapMinus,
  mdiInformationOutline,
} from '@mdi/js'

// eslint-disable-next-line import/no-unresolved
import Contact from '@/views/user_register/utils/Contact.vue'
// eslint-disable-next-line import/no-unresolved
import Email from '@/views/user_register/utils/Email.vue'
// eslint-disable-next-line import/no-unresolved
import Telefono from '@/views/user_register/utils/Telefono.vue'

export default {
  components: {
    AppCardCode,
    Contact,
    Email,
    Telefono,
  },
  data() {
    return {
      isLoading: true,
      editGeoTag: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiMapPlus,
        mdiMapMinus,
        mdiInformationOutline,
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      item: {},
      isotipo: null,
      imagotipo: null,
      loading: false,
      products: [],
      nom_tipo_products_id: [],
    }
  },
  computed: {
    btnTitle() {
      return sessionStorage.getItem('aerolinea-flights-id') === null ? this.$t('btn.send') : this.$t('btn.update')
    },
    ...mapState({
      contactsAfiliados: state => state.app.contactsAfiliados,
      onlyShow: state => state.app.onlyShow,
    }),
  },
  created() {
    if (sessionStorage.getItem('aerolinea-flights-show') !== null) {
      this.setOnlyShow(true)
    } else {
      this.setOnlyShow(false)
    }
    this.getProducts()
    this.getItem()
  },
  methods: {
    ...mapMutations(['setOnlyShow', 'addContactsAfiliados', 'deleteContactsAfiliados', 'updateContactsAfiliados']),

    getProducts() {
      this.axios
        .get('type_product?per_page=1000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.products = res.data.data
          this.products.forEach(element => {
            if (element.slug === 'flights') {
              this.nom_tipo_products_id.push(element.id)
            }
          })
        })
    },
    getItem() {
      if (sessionStorage.getItem('aerolinea-flights-id') !== null) {
        const id = sessionStorage.getItem('aerolinea-flights-id')
        this.axios
          .get(`nom_aerolines/${id}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.item = res.data.data.data
            if (this.item.nom_tipo_products_id) {
              this.nom_tipo_products_id = this.item.nom_tipo_products_id
            } else {
              this.nom_tipo_products_id = []
            }

            if (this.item.contacts !== null && this.item.contacts !== 'null') {
              this.updateContactsAfiliados(this.item.contacts)
            } else {
              this.updateContactsAfiliados([])
            }
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => (this.isLoading = false))
      } else {
        this.isLoading = false
      }
    },

    save() {
      if (this.item.name && this.item.slug) {
        this.loading = true
        this.item.perfil_slug = sessionStorage.getItem('perfil')
        const formData = new FormData()
        if (this.isotipo) {
          formData.append('isotipo', this.isotipo)
        }
        if (this.imagotipo) {
          formData.append('imagotipo', this.imagotipo)
        }

        this.item.nom_tipo_products_id = this.nom_tipo_products_id
        this.item.contacts = this.contactsAfiliados

        formData.append('data', JSON.stringify(this.item))

        if (sessionStorage.getItem('aerolinea-flights-id') === null) {
          this.axios
            .post('nom_aerolines', formData, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === false) {
                if (response.data.data.status === 401) {
                  // this.$toast.error(this.$t('msg.noAutorice'))
                } else if (response.data.data.status === 402) {
                  this.$toast.error(this.$t('msg.airlineExist', { identificador: this.item.slug }))
                }
              } else {
                this.$toast.success(this.$t('msg.infoSuccess'))
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => this.reseat())
        } else {
          this.axios
            .post(`nom_aerolines/actualice/${sessionStorage.getItem('aerolinea-flights-id')}`, formData, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === false) {
                // this.$toast.error(this.$t('msg.noAutorice'))
              } else {
                this.$toast.success(this.$t('msg.infoSuccess'))
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => this.reseat())
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    reseat() {
      this.item = {}
      this.loading = false
      this.$router.push({ name: 'aerolinea-flights-list' })
    },
  },
}
</script>
